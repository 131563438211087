import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

const ID_USER_KEY = "user_info" as string;

export interface User {
  id: string;
  studentId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  cashRedeemableBalance: number;
  itemRedeemableBalance: number;
  totalRedeemableBalance: number;
  status: string;
  role: string;
}

const userDefaults = {
  id: "",
  studentId: "",
  firstName: "",
  lastName: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  cashRedeemableBalance: 0,
  itemRedeemableBalance: 0,
  totalRedeemableBalance: 0,
  status: "",
  role: "",
};

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = {} as User;
  otp = {};
  errors = {};
  tokens = {} as Tokens;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    const userInfoString = window.localStorage.getItem(ID_USER_KEY);

    if (userInfoString) {
      const userInfoLocalStorage = JSON.parse(userInfoString);

      Object.keys(userDefaults).forEach((key) => {
        this.user[key] = userInfoLocalStorage[key]
          ? userInfoLocalStorage[key]
          : userDefaults[key];
      });
    }

    return this.user;
  }

  /**
   * Get current token object
   * @returns Tokens
   */
  get currentTokens(): Tokens {
    return this.tokens;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get otp status
   * @returns object
   */
  get getOTP() {
    return this.otp;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](tokens) {
    this.isAuthenticated = true;
    this.tokens = {
      accessToken: tokens.access.token,
      refreshToken: tokens.refresh.token,
    };
    this.errors = {};
    JwtService.saveToken(tokens.access.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    user.role = user.role.charAt(0).toUpperCase() + user.role.slice(1);
    user.fullName = `${user.firstName} ${user.lastName}`;
    user.totalRedeemableBalance =
      user.cashRedeemableBalance + user.itemRedeemableBalance;
    window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
    this.user = user;
  }

  @Mutation
  [Mutations.SET_OTP](otp) {
    this.otp = otp;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("auth/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_OTP, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          login: response.data.message,
        });
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.OTP](token) {
    return ApiService.post("auth/otp", token)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data.user);
        this.context.commit(Mutations.SET_AUTH, data.tokens);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          otp: response.data.message,
        });
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("users/@me")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
