import { App } from "vue";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";

/**
 * Initialize Lottie component
 * @param app vue instance
 */
export function initLottie(app: App<Element>) {
  app.use(Vue3Lottie);
}
