import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/app/dashboard/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
        },
      },
      {
        path: "shop",
        name: "shop",
        component: () => import("@/views/app/shop/Shop.vue"),
        meta: {
          pageTitle: "Shop",
        },
      },
      {
        path: "enrollments",
        name: "enrollments",
        component: () => import("@/views/app/enrollments/Enrollments.vue"),
        meta: {
          pageTitle: "Enrollments",
        },
      },
      {
        path: "/admin/enrollments",
        name: "admin-enrollments-listing",
        component: () => import("@/views/admin/enrollments/EnrollmentList.vue"),
        meta: {
          pageTitle: "Enrollments",
          breadcrumbs: ["Admin", "Enrollments"],
        },
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/authentication/SignIn.vue"),
    meta: {
      pageTitle: "Sign In",
    },
  },
  {
    path: "/otp/:token",
    name: "otp",
    component: () => import("@/views/authentication/OTP.vue"),
    meta: {
      pageTitle: "Sign In",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/utils/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/utils/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} | ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // check if page is not otp and verify token
  if (to.name !== "otp") {
    store.dispatch(Actions.VERIFY_AUTH);
  }

  // check if user is set
  if (!store.getters.isUserAuthenticated) {
    store.dispatch(Actions.LOGOUT);
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
